import Roles from '@/shared/utils/roles'
import PermissionMixin from '@/store/modules/permission/mixin'

export default {
  name: 'roles',

  mixins: [PermissionMixin],

  data: () => ({
    roles: Roles
  }),

  methods: {
    hasRole (role) {
      let result = false
      if (this.permissions) {
        for (let i in this.permissions) {
          if (this.permissions[i].role === role) {
            result = true
          }
        }
      }
      return result
    }
  }
}
