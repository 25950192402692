const roles = {
MENU_ADMINISTRATION: 'MENU_ADMINISTRATION',
R_ACCOUNTS: 'R_ACCOUNTS',
W_ACCOUNTS: 'W_ACCOUNTS',
R_GROUPS: 'R_GROUPS',
W_GROUPS: 'W_GROUPS',
R_PERMISSIONS: 'R_PERMISSIONS',
W_PERMISSIONS: 'W_PERMISSIONS',
R_SMSLOGS: 'R_SMSLOGS',

R_STUDENTS: 'R_STUDENTS',
W_STUDENTS: 'W_STUDENTS',
R_STUDENT_SOCIALATTENDANCE: 'R_STUDENT_SOCIALATTENDANCE',
W_STUDENT_SOCIALATTENDANCE: 'W_STUDENT_SOCIALATTENDANCE',

MENU_CONFIGURATION: 'MENU_CONFIGURATION',
R_MODALITY: 'R_MODALITY',
W_MODALITY: 'W_MODALITY',
R_LOCALTRAINING: 'R_LOCALTRAINING',
W_LOCALTRAINING: 'W_LOCALTRAINING',
R_CATEGORY: 'R_CATEGORY',
W_CATEGORY:'W_CATEGORY',
R_TEACHERS: 'R_TEACHERS',
W_TEACHERS: 'W_TEACHERS',
R_BENEFITYTYPE: 'R_BENEFITYTYPE',
W_BENEFITYTYPE: 'W_BENEFITYTYPE',
R_SCHOOLS: 'R_SCHOOLS',
W_SCHOOLS: 'W_SCHOOLS',
R_ATTACHMENTTYPE: 'R_ATTACHMENTTYPE',
W_ATTACHMENTTYPE: 'W_ATTACHMENTTYPE',
R_OCCURRENCYTYPE: 'R_OCCURRENCYTYPE',
W_OCCURRENCYTYPE: 'W_OCCURRENCYTYPE',
R_ATTENDANCETYPE: 'R_ATTENDANCETYPE',
W_ATTENDANCETYPE: 'W_ATTENDANCETYPE',
R_SOCIALENTITY: 'R_SOCIALENTITY',
W_SOCIALENTITY: 'W_SOCIALENTITY',
R_ANAMNESE: 'R_ANAMNESE',
W_ANAMNESE: 'W_ANAMNESE',

R_CLASSES: 'R_CLASSES',
W_CLASSES: 'W_CLASSES',

R_WAITINGLIST: 'R_WAITINGLIST',
W_WAITINGLIST: 'W_WAITINGLIST',
R_WAITINGLIST_TEST: 'R_WAITINGLIST_TEST',
W_WAITINGLIST_TEST: 'W_WAITINGLIST_TEST',

D_WAITINGLIST_HISTORY_CALL: 'D_WAITINGLIST_HISTORY_CALL',

MENU_REPORTS: 'MENU_REPORTS',
REPORTS_STUDENTS: 'REPORTS_STUDENTS',
REPORTS_FREQUENCY: 'REPORTS_FREQUENCY',
REPORTS_STUDENTS_MODALITY: 'REPORTS_STUDENTS_MODALITY',
REPORTS_STUDENTS_LOCALTRAINING: 'REPORTS_STUDENTS_LOCALTRAINING',
REPORTS_STUDENTS_SCHOOL: 'REPORTS_STUDENTS_SCHOOL',
REPORTS_STUDENTS_FAMILYINCOME: 'REPORTS_STUDENTS_FAMILYINCOME',
REPORTS_STUDENTS_GENDER: 'REPORTS_STUDENTS_GENDER',
REPORTS_STUDENTS_AGE: 'REPORTS_STUDENTS_AGE',
REPORTS_STUDENTS_DISTRICT: 'REPORTS_STUDENTS_DISTRICT',
REPORTS_STUDENTS_OCCURRENCY: 'REPORTS_STUDENTS_OCCURRENCY',
REPORTS_STUDENTS_MEASUREMENTS: 'REPORTS_STUDENTS_MEASUREMENTS',
REPORTS_STUDENTS_SOCIALENTITY: 'REPORTS_STUDENTS_SOCIALENTITY',

MENU_TOOLS: 'MENU_TOOLS',
R_TOOLS_BATCHSITUATION: 'R_TOOLS_BATCHSITUATION',
W_TOOLS_BATCHSITUATION: 'W_TOOLS_BATCHSITUATION',
R_TOOLS_EXPORTEXCEL: 'R_TOOLS_EXPORTEXCEL',
W_TOOLS_EXPORTEXCEL: 'W_TOOLS_EXPORTEXCEL',
R_TOOLS_STUDENTMEETING: 'R_TOOLS_STUDENTMEETING',
W_TOOLS_STUDENTMEETING: 'W_TOOLS_STUDENTMEETING',
R_WAITINGLIST_WEB: 'R_WAITINGLIST_WEB',
W_WAITINGLIST_WEB: 'W_WAITINGLIST_WEB',

R_PHYSIOLOGY: 'R_PHYSIOLOGY',
W_PHYSIOLOGY: 'W_PHYSIOLOGY',
}

export default roles
