<template>
  <div>
    <b-form-group
      label-cols-sm="4"
      label-cols-lg="3"
      :label="labelSearch"
      label-for="input-horizontal"
      class="label"

    >
      <b-input-group>
        <b-form-input
          :state="state"
          placeholder="Busca" 
          v-model="searchText" 
          @keyup.enter="search" 
          aria-describedby="input-live-help input-live-feedback"
          id="input-live"
          trim />

        <b-input-group-append>
          <b-button variant="outline-danger" @click="clear">
            <i class="fa fa-close" />
          </b-button>
          <b-button 
            class="search-button"
            variant="info" 
            @click="search">
            <i class="fa fa-search" />
          </b-button>
        </b-input-group-append>
      <b-form-invalid-feedback id="input-live-feedback">Digite pelo menos 2 caracteres.</b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "Search",

  props: {
    labelSearch: String,
  },

  data: () => ({
    searchText: '',
  }),

  computed: {
    state() {
      if (this.searchText) {
        return this.searchText.length >= 2
      }
      return null
    },
  },

  methods: {
    search() {
      this.$emit('search', this.searchText)
    },
    clear() {
      this.searchText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .label {
    font-weight: bolder;
    font-size: 1.8rem;
    color: black;
  }
  
  #input-live-feedback {
    font-size: 0.8rem;
    font-weight: normal;
  }

  .search-button {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important; 
  }
</style>